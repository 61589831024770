import React from "react";
import Home from "./pages/Home";
import RemovingNewsOrAnyOtherLinks from "./pages/RemovingNewsOrAnyOtherLinks";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RemovingReviews from "./pages/RemovingReviews";
import DeletingInformation from "./pages/DeletingInformation";
import RemovingGlassdoor from "./pages/RemovingGlassdoor";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/removing-news-or-any-other-links-from-google-search" element={<RemovingNewsOrAnyOtherLinks />} />
        <Route path="/services/removing-rewiews-from-the-google-my-business-card" element={<RemovingReviews />} />
        <Route path="/services/removing-reviews-from-glassdoor-tripadvisor-yelp" element={<RemovingGlassdoor />} />
        <Route path="/services/deleting-personal-information-from-the-internet" element={<DeletingInformation />} />
      </Routes>
    </div>
  );
}

export default App;