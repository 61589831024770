import React from "react";
import "../App.css";
import Main from "../components/Main";
import { Quiz } from "../components/Quiz";
import { QuizForm } from "../components/QuizForm";
import { Process } from "../components/Process";
import { Guarantees } from "../components/Guarantees";
import { Comparing } from "../components/Comparing";
import { Cases } from "../components/Cases";
import { FormBottom } from "../components/FormBottom";
import Footer from "../components/Footer";
import { ModalCallMe } from "../components/ModalCallMe";
import { ModalsContainer } from "../components/ModalsContainer";
import { RealFooter } from "../components/RealFooter";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Home() {
  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <title>Online reputation services for brand management and social media repair | Reputation Coalition</title>
          <meta name="description" content="We offer online reputation services for businesses and individuals, specialize in brand reputation management, social media repair, and PR. Control your internet presence across search engines and Google reviews with Reputation Coalition." />
          <meta name="keywords" content="online reputation services, firm, brand reputation, social media, repair, agency, business, corporate, good, consultant, internet, search engine, control, pr, restoration, google, reviews" />
        </Helmet>
      </HelmetProvider>

      <Main heading="Online reputation services" text="We can help you improve your reputation with our comprehensive online reputation services. At Reputation Coalition, we specialize in managing and repairing the online image of businesses and brands. Whether you're facing negative reviews, social media crises, or simply want to enhance your brand's presence on the internet, our expert team is here to assist you in taking control of your online reputation."></Main>
      <Quiz>
        <QuizForm></QuizForm>
      </Quiz>
      <Process></Process>
      <Guarantees></Guarantees>
      <Comparing></Comparing>
      <Cases></Cases>
      
      <>
        <section className=" py-12 " id="text">
          <div className="container">
            <div className="section_content text-white text-lg section_guarantees	text-z">
              <h2 className="mb-5"><strong>Why You Need Online Reputation Services for Brand Management</strong></h2>
              <p className="mb-2">In the digital age, your brand's reputation is one of its most valuable assets. A single negative review or social media mishap can quickly spiral out of control, causing lasting damage to your business. This is where online reputation services come into play. By proactively managing your brand's online presence, you can prevent potential crises, restore your good standing, and ensure that your business is viewed positively across search engines and social media platforms.</p>
              <p className="mb-5">Our online reputation services are designed to help businesses and corporate entities maintain a strong, positive image. From handling negative reviews to boosting your brand&rsquo;s visibility on Google and other search engines, our firm provides tailored strategies that address your unique needs. Whether you&rsquo;re a small business or a large corporation, our services are crucial for maintaining customer trust, attracting new clients, and protecting your brand's integrity.</p>
              <h2 className="mb-5"><strong>How Online Reputation Services Help and What They Provide</strong></h2>
              <p className="mb-5">Our online reputation services offer several key benefits for your brand:</p>
              <ol className="mb-5 list-disc ml-5">
              <li><strong>Brand Reputation Management:</strong> We monitor and manage your brand's online presence, ensuring that negative content is addressed promptly. Our team of consultants works to repair any damage caused by unfavorable reviews or negative press, helping to restore your brand&rsquo;s reputation.</li>
              <li><strong>Social Media Repair:</strong> Social media is a powerful tool for building your brand, but it can also be a source of reputational risk. We provide social media repair services to mitigate the impact of negative comments, posts, or campaigns. By managing your social media profiles, we ensure that your brand maintains a positive and professional image.</li>
              <li><strong>Search Engine Control:</strong> Negative content can easily surface in search engine results, damaging your brand&rsquo;s credibility. Our services include search engine optimization (SEO) and content management to push down negative results and promote positive content, helping you regain control over your brand&rsquo;s online narrative.</li>
              <li><strong>Corporate PR and Restoration:</strong> For businesses facing public relations challenges, our corporate PR services offer targeted strategies to restore your good standing. We work to rebuild your brand&rsquo;s image, ensuring that your business is seen in a positive light by customers, investors, and the media.</li>
              <li><strong>Comprehensive Internet Monitoring:</strong> We continuously monitor the internet for mentions of your brand, allowing us to act swiftly in response to any potential threats to your reputation. This proactive approach helps prevent small issues from becoming major crises.</li>
              </ol>
              <h2 className="mb-5"><strong>Take Control of Your Brand's Online Reputation Today</strong></h2>
              <p className="mb-2">At Reputation Coalition, we understand the importance of a good online reputation for your business. Our online reputation services are designed to help you maintain control over your brand&rsquo;s image, repair any damage, and ensure that your business is represented accurately and positively across the internet.</p>
              <p className="mb-2">Don't let negative content or social media issues define your brand. Take the first step towards a stronger online presence today by ordering our online reputation services. Contact us now to learn more about how we can help you with brand reputation management, social media repair, and corporate PR strategies.</p>
              <p className="mb-5">Reputation Coalition is your trusted agency for online reputation management. We&rsquo;re here to help you control your brand&rsquo;s image, repair your online presence, and ensure that your business maintains a strong, positive reputation on the internet.</p>
            </div>
          </div>
        </section>
      </>

      <Footer></Footer>
      <ModalsContainer></ModalsContainer>
      <RealFooter></RealFooter>
    </div>
  );
}

export default Home;
